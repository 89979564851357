<template>
  <span>
    <el-button size="mini" type="primary" @click="start(id)">{{id ? $t('button.edit') : $t('button.add')}}</el-button>
    <el-dialog
      :title="id ? $t('button.edit') : $t('button.add')"
      :visible.sync="dialogVisible"
      width="400px">
      <div v-loading="loading">
        <el-form
          ref="form"
          :rules="rules"
          :model="page"
          label-width="auto"
          size="medium">
          <el-form-item :label="$t('page.name')" prop="name">
            <el-input v-model="page.name" :placeholder="$ts('placeholder.input', 'page.name')"/>
          </el-form-item>
          <el-form-item :label="$t('page.coverImage')" prop="coverImage" style="text-align: left">
            <div class="choose-image" v-if="page.coverImage">
              <img :src="page.coverImage" :alt="$t('page.coverImage')"/>
              <div class="delete" @click="$set(page, 'coverImage', '')"/>
            </div>
            <el-button v-else size="mini" type="primary" :loading="uploading" @click="handleUploadCoverImage">{{$t('page.uploadImage')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button size="medium" type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
        <el-button size="medium" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {detail, save} from '@/api/page'
import {chooseFile, showSuccessMsg} from "@/utils/func";
import {uploadFile} from "@/api/common";
import defaultPageData from '@/components/ConfigurationEditor/data/defaultPageData'

export default {
  name: "SavePageBtn",
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      page: {},
      rules: {
        name: [{required: true, message: this.$ts('placeholder.input', 'page.name'), trigger: 'blur'},],
        coverImage: [{required: true, message: this.$ts('placeholder.select', 'page.coverImage'), trigger: 'blur'},]
      },
      setIng: false,
      uploading: false,
      data: defaultPageData
    }
  },
  methods: {
    start(id) {
      this.dialogVisible = true
      this.loading = false
      this.page = {}
      this.setIng = false
      if (id) {
        this.getInfo(id)
      }
    },
    getInfo(id) {
      this.loading = true
      detail({id}).then(res => {
        this.page = res.data
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    handleUploadCoverImage() {
      chooseFile(file => {
        this.uploading = true
        uploadFile(file).then(res => {
          this.$set(this.page, 'coverImage', res.data)
        }).finally(() => {
          setTimeout(() => {
            this.uploading = false
          }, 200)
        })
      }, '.jpg,.png,.gif,.svg')
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.setIng = true
          let data = JSON.parse(JSON.stringify(this.page))
          data.data = JSON.stringify(this.data);
          save(data).then(() => {
            this.dialogVisible = false
            showSuccessMsg(this.$t('message.title'), (this.page.id ? this.$t('message.editSuccess') : this.$t('message.addSuccess')))
            this.$emit('xzdListPageReload', !this.page.id)
          }).finally(() => {
            setTimeout(() => {
              this.setIng = false
            }, 500)
          })
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
.choose-image {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #eeeeee;

  .c-icon {
    font-size: 90px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .delete {
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: red;
    right: -8px;
    top: -8px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999999;

    &:after,
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 2px;
      background-color: white;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(135deg);
    }
  }

  &:hover {
    .delete {
      display: block;
    }
  }
}
</style>