<template>
  <span>
    <el-button size="mini" type="primary" @click="start">{{$t('page.addFromMarket')}}</el-button>
   <el-dialog
     :title="$t('page.addFromMarket')"
     :visible.sync="dialogVisible"
     width="900px">
     <el-skeleton :loading="loading" animated>
      <template slot="template">
        <div class="page-list">
          <template v-for="i in 4">
            <div class="page-item">
              <el-skeleton-item
                class="cover"
                variant="image"
              />
              <div class="page-name">
                <el-skeleton-item variant="text"/>
                <el-skeleton-item variant="text"/>
              </div>
              <div class="bottom">
                <el-skeleton-item variant="text" style="width: 80px"/>
                <el-skeleton-item variant="text" style="width: 80px"/>
              </div>
            </div>
          </template>
        </div>
      </template>
      <el-tabs type="border-card">
        <template v-for="marketGroup in dataList">
          <el-tab-pane :label="marketGroup.marketGroupName">
            <div class="page-list">
              <template v-for="page in marketGroup.pageList">
                <div class="page-item">
                  <div class="cover">
                    <img class="cover-image" alt="coverImage" :src="page.coverImage"/>
                  </div>
                  <div class="page-name" :title="page.name">{{page.name}}</div>
                  <div class="bottom">
                    <el-button type="primary" size="mini" :loading="page.loading" @click="handleUse(page)">{{$t('page.use')}}</el-button>
                    <el-button type="primary" size="mini" :loading="page.loading" @click="handlePreview(page)">{{$t('page.preview')}}</el-button>
                  </div>
                </div>
              </template>
            </div>
          </el-tab-pane>
        </template>
      </el-tabs>
     </el-skeleton>
    </el-dialog>
  </span>
</template>
<script>
import {detail, listInMarket, save} from "@/api/page";
import {showSuccessMsg} from "@/utils/func";
import moment from 'moment'

export default {
  name: "AddFromMarketButton",

  props: {
    id: {},
  },

  data() {
    return {
      dialogVisible: false,
      page: {},
      rules: {
        marketGroupId: [
          {required: true, message: this.$ts('placeholder.select', 'page.marketGroup'), trigger: 'blur'},
        ]
      },
      loading: false,
      setIng: false,
      dataList: []
    }
  },

  methods: {
    start() {
      this.page = {}
      this.dialogVisible = true
      this.loadData()
    },

    loadData() {
      this.loading = true
      listInMarket().then(res => {
        this.dataList = res.data
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },

    handleUse(page) {
      this.$set(page, 'loading', true)

      detail({id: page.id}).then(res => {
        let {name, data, coverImage} = res.data
        let saveData = {
          name: name + ' - ' + moment().format('YYYY-MM-DD HH:mm:ss'),
          data,
          coverImage
        }
        save(saveData).then(() => {
          this.dialogVisible = false
          showSuccessMsg(this.$t('message.title'), this.$t('message.saveSuccess'))
          this.$emit('xzdListPageReload', !this.page.id)
        }).finally(() => {
          setTimeout(() => {
            this.$set(page, 'loading', false)
          }, 500)
        })
      }).catch(() => {
        setTimeout(() => {
          this.$set(page, 'loading', false)
        }, 500)
      })
    },

    handlePreview(page) {
      window.open(`./#/show/v2?id=${page.id}`)
    }
  }
}
</script>
<style scoped lang="scss">
.page-list {
  display: flex;
  flex-wrap: wrap;
}

.page-item {
  width: 189.5px;
  margin-right: 23px;
  margin-bottom: 24px;
  background-image: url("~@/assets/images/cover-bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;

  &:nth-child(4n) {
    margin-right: 0;
  }

  .cover {
    width: 100%;
    height: 142px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    background-color: #eeeeee;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .page-name {
    color: black;
    font-size: 14px;
    margin-top: 4px;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    height: 38px;
  }

  .bottom {
    padding: 8px;
    display: flex;
    justify-content: space-between;
  }
}
</style>