<template>
  <div>
    <el-form size="medium" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item :label="$t('page.name')">
            <el-input v-model.trim="queryParam.name" :placeholder="$ts('placeholder.input', 'page.name')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0">
            <el-button type="primary" @click="handleSearch(queryParam)">{{$t('button.search')}}</el-button>
            <el-button type="primary" @click="handleSearch({})">{{$t('button.reset')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "PageListSearchBox",
  
  model: {
    prop: 'queryParam',
    event: 'change'
  },
  
  props: {
    queryParam: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  
  methods: {
    handleSearch(queryParam) {
      this.$emit('change', queryParam)
      this.$nextTick(() => {
        this.$emit('onSearch')
      })
    }
  }
}
</script>