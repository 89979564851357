import { render, staticRenderFns } from "./AddFromMarketButton.vue?vue&type=template&id=6ff0a92a&scoped=true"
import script from "./AddFromMarketButton.vue?vue&type=script&lang=js"
export * from "./AddFromMarketButton.vue?vue&type=script&lang=js"
import style0 from "./AddFromMarketButton.vue?vue&type=style&index=0&id=6ff0a92a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff0a92a",
  null
  
)

export default component.exports