<template>
  <span>
    <el-button size="mini" type="primary" @click="start">{{$t('page.setMarketGroup')}}</el-button>
   <el-dialog
     :title="$t('page.setMarketGroup')"
     :visible.sync="dialogVisible"
     width="400px">
      <div v-loading="loading">
        <el-form
          ref="form"
          :rules="rules"
          :model="page"
          label-width="auto"
          size="medium">
          <el-form-item :label="$t('page.marketGroup')" prop="marketGroupId">
            <el-select v-model="page.marketGroupId" :placeholder="$ts('placeholder.input', 'page.marketGroup')" style="width: 100%">
              <el-option :value="0" label="无分组"/>
              <template v-for="group in marketGroupList">
                <el-option :value="group.id" :label="group.name"/>
              </template>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button size="medium" type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
        <el-button size="medium" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {detail, save} from "@/api/page";
import {list as getMarketGroup} from "@/api/marketGroup";
import {showSuccessMsg} from "@/utils/func";

export default {
  name: "SetMarketGroupButton",
  
  props: {
    id: {},
  },
  
  data() {
    return {
      dialogVisible: false,
      page: {},
      rules: {
        marketGroupId: [
          {required: true, message: this.$ts('placeholder.select', 'page.marketGroup'), trigger: 'blur'},
        ]
      },
      loading: false,
      setIng: false,
      marketGroupList: []
    }
  },
  
  methods: {
    start() {
      this.page = {}
      this.dialogVisible = true
      this.loading = true
      Promise.allSettled([this.getMarketGroup(), this.getInfo()]).then(res => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    getMarketGroup() {
      return getMarketGroup({pageNo: 1, pageSize: 99999}).then(res => {
        this.marketGroupList = res.data.list
      })
    },
    getInfo() {
      return detail({id: this.id}).then(res => {
        this.page = res.data
      })
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
      
          this.setIng = true
          let data = JSON.parse(JSON.stringify(this.page))
      
          save(data).then(() => {
            this.dialogVisible = false
            showSuccessMsg(this.$t('message.title'), this.$t('message.editSuccess'))
            this.$emit('xzdListPageReload', !this.page.id)
          }).finally(() => {
            setTimeout(() => {
              this.setIng = false
            }, 500)
          })
        }
      });
    }
  }
}
</script>
<style scoped>
</style>