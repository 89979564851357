export default {
  "x": 0,
  "y": 0,
  "scale": 1,
  "pens": [],
  "origin": {
    "x": 0,
    "y": 0
  },
  "center": {
    "x": 0,
    "y": 0
  },
  "grid": true,
  "gridSize": 100,
  "rule": true,
  "locked": 0,
  "bindDataConfig": {
    "write": {
      "enable": true,
      "url": "https://iot.tengineiot.cn/prod-api/ti/ztOpenApi/publishTopicMessage",
      "type": "http",
      "httpMethod": "POST",
      "httpHeaders": {},
      "httpBody": null,
      "mqttUserName": null,
      "mqttPassword": null,
      "mqttClientId": null,
      "mqttTopics": null,
      "script": `/**
 * 脚本全局变量
 * topPageId:当前组态顶级id
 * pageId:当前组态id
 * engine:当前组态实例
 * qs:对象和url参数互转工具
 * dataList:当前或全部绑定变量的数据列表，格式[{dataId: xxx, value: xxx}]
 */

//获取http请求头信息
function getHttpHeaders() {
    return {
        "Content-Type": "application/json"
    }
}

//获取http请求参数，拼接在url上
function getHttpParams() {
    return null
}

//获取http请求内容
function getHttpBody() {
    let dataListMap = dataList.map(item => {
      return {modelName: item.dataId, value: item.value}
    });
    let bodyData = {
        ztId: topPageId,
        dataList: dataListMap
    } 
    return bodyData;
}

//获取websocket上报内容
function getWebsocketBody() {
    return null
}

//获取mqtt订阅主题，多个以,隔开，可以不订阅
function getMqttSubTopic() {

}

/**
 * 获取mqtt发布内容对象，每个topic一个键值对，例如：
 * {
 *     "/device/send/": {
 *         id: 1,
 *         value: 12.4
 *     }
 * }
 */
function getMqttPubObject() {
    return null
}

/**
 * 转换响应内容
 * @param data 响应的数据
 * @param topic mqtt的topic值
 * @return 转换后的数据数组
 */
function onResponseData(data, topic) {
  return data
}`
    },
    "read": {
      "enable": true,
      "url": "https://iot.tengineiot.cn/prod-api/ti/ztOpenApi/getDeviceModePublish",
      "type": "http",
      "httpMethod": "GET",
      "httpHeaders": {},
      "httpBody": null,
      "mqttUserName": null,
      "mqttPassword": null,
      "mqttClientId": null,
      "mqttTopics": null,
      "enabled": true,
      "script": `/**
 * 脚本全局变量
 * topPageId:当前组态顶级id
 * pageId:当前组态id
 * engine:当前组态实例
 * qs:对象和url参数互转工具
 * dataIds:当前或全部绑定变量的唯一标识
 */

//获取http请求头信息
function getHttpHeaders() {
    return {}
}

//获取http请求参数，拼接在url上
function getHttpParams() {
    return \`ztId=\${topPageId}&modelNames=\${dataIds.join(',')}\`
}

//获取http请求内容
function getHttpBody() {
    return null
}

//获取websocket上报内容
function getWebsocketBody() {
    return null
}

//获取mqtt订阅主题，多个以,隔开，可以不订阅
function getMqttSubTopic() {

}

/**
 * 获取mqtt发布内容对象，每个topic一个键值对，例如：
 * {
 *     "/device/send/": {
 *         id: 1,
 *         value: 12.4
 *     }
 * }
 */
function getMqttPubObject() {
    return null
}

/**
 * 转换响应内容
 * @param data 响应的数据
 * @param topic mqtt的topic值
 * @return 转换后的数据数组
 */
function onResponseData(data, topic) {
  return data.data
}`
    },
    "list": {
      "deviceUrl": "https://iot.tengineiot.cn/prod-api/ti/ztOpenApi/getDeviceList",
      "deviceMethod": "GET",
      "deviceParamScript": `/**
 * 脚本全局变量
 * topPageId:当前组态顶级id
 * pageId:当前组态id
 * engine:当前组态实例
 * qs:对象和url参数互转工具
 * pageNum:当前页码
 * pageSize:分页大小
 * queryParam:搜索参数对象
 */

//获取http请求头信息
function getHttpHeaders() {
    return {}
}

//获取http请求参数，拼接在url上
function getHttpParams() {
    return \`ztId=\${topPageId}&pageNum=\${pageNum}&pageSize=\${pageSize}&\${qs.stringify(queryParam)}\`
}

//获取http请求内容
function getHttpBody() {
    return null
}`,
      "url": "https://iot.tengineiot.cn/prod-api/ti/ztOpenApi/getDeviceModelList",
      "method": "GET",
      "paramScript": `/**
 * 脚本全局变量
 * topPageId:当前组态顶级id
 * pageId:当前组态id
 * engine:当前组态实例
 * qs:对象和url参数互转工具
 * pageNum:当前页码
 * pageSize:分页大小
 * queryParam:搜索参数对象
 */

//获取http请求头信息
function getHttpHeaders() {
    return {}
}

//获取http请求参数，拼接在url上
function getHttpParams() {
    return \`ztId=\${topPageId}&pageNum=\${pageNum}&pageSize=\${pageSize}&\${qs.stringify(queryParam)}\`
}

//获取http请求内容
function getHttpBody() {
    return null
}`,
      "totalProperty": "total",
      "listProperty": "rows",
      "idProperty": "deviceIdentifier",
      "keyProperty": "value",
      "searchPropertyList": [
        {
          "key": "modelName",
          "label": "名称"
        }
      ],
      "showPropertyList": [
        {
          "key": "identifier",
          "label": "标识符"
        },
        {
          "key": "modelName",
          "label": "名称"
        },
        {
          "key": "datatype",
          "label": "数据类型"
        }
      ],
    },
    "sync": {
      "url": "https://iot.tengineiot.cn/prod-api/ti/ztOpenApi/getDeviceModelLogList",
      "type": "http",
      "httpMethod": "GET",
      "httpTimeInterval": 3000,
      "httpHeaders": {},
      "httpBody": null,
      "mqttUserName": null,
      "mqttPassword": null,
      "mqttClientId": null,
      "mqttTopics": null,
      "script": `/**
 * 脚本全局变量
 * topPageId:当前组态顶级id
 * pageId:当前组态id
 * engine:当前组态实例
 * qs:对象和url参数互转工具
 * dataIds:全部绑定变量的唯一标识
 */
//获取http请求头信息
function getHttpHeaders() {
    return {}
}

//获取http请求参数，拼接在url上
function getHttpParams() {
    return \`ztId=\${topPageId}&modelNames=\${dataIds.join(',')}\`
}

//获取http请求内容
function getHttpBody() {
    return null
}

/**
 * 转换响应内容
 * @param data 响应的数据
 * @return 转换后的数据数组
 */
function onResponseData(data) {
  return data.data
}`
    }
  },
  "version": "1.0"
}